.ag-theme-alpine {
    --ag-font-size: 13px;
    --ag-font-family: "Montserrat" !important;
}

.ag-theme-alpine .ag-row {
    font-size: 13px;
}

.ag-input-field-input {
    flex: 1 1 auto;
    width: 100%;
    min-width: 0;
    font-family: "Montserrat" !important;
}

.number-cell {
    text-align: left;
}